
import { mapGetters } from "vuex"
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

export default {
  name: 'DaOptin',
  mixins: [fieldLabelAndTooltip],
  props: [
    'propertyName',
    'baseProperty',
    'fieldName',
    'fieldLabel',
    'instructions',
    'toolTipTitle',
    'toolTipContent',
    'defaultOptIn',
    'v',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isoptin: null,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    emailOptIn () {
      return this.getCurrent.email_opt_in
    },
    mobileOptIn () {
      return this.getCurrent.mobile_opt_in
    },
    phoneOptIn () {
      return this.getCurrent.phone_opt_in
    },
    doNotCall () {
      return this.phoneOptIn!==true // mobile phones do count against "do_not_call"
    },
    doNotContact () {
      return false // if a member says do_not_contact, then the member gets deleted; hence do_not_contact is set to false
    },
    propertyOptIn () {
      return this.getCurrent[this.propertyName] !== undefined ? this.getCurrent[this.propertyName] : null
    },
    ...mapGetters("requests", ["getCurrent"]),
  },
  methods: {
    set_optin (value) {
      /*
      if (value!==null) {
        this.$store.commit('requests/update', { [this.propertyName]: value })
        if (!value) {this.$store.commit('requests/update', { [this.baseProperty]: null })}
      } else {
        this.$store.commit('requests/update', { [this.propertyName]: null, [this.baseProperty]: null })
        this.$store.commit('requests/removeProperty', [this.baseProperty])
      }
      this.$store.commit('requests/update',
        { do_not_call: this.doNotCall,
          do_not_contact: this.doNotContact
        })
      */
      if (value===null) {
        this.$store.commit('requests/update', { [this.propertyName]: null, [this.baseProperty]: null })
        this.$store.commit('requests/removeProperty', [this.propertyName, this.baseProperty])
      } else {
        this.$store.commit('requests/update', { [this.propertyName]: value })
        this.$store.commit('requests/update',
          { do_not_call: this.doNotCall,
            do_not_contact: this.doNotContact
          })
        /* if (!value) { this.$store.commit('requests/update', { [this.baseProperty]: null }) } */
      }

      this.$emit('delayTouch')
    },
  },
  mounted () {
    this.isoptin = this.getCurrent[this.propertyName] !== undefined ? this.getCurrent[this.propertyName] : (this.defaultOptIn !== undefined ? this.defaultOptIn : null)
    this.set_optin(this.isoptin)
  },
  watch: {
    propertyOptIn () {
      this.isoptin = this.getCurrent[this.propertyName] !== undefined ? this.getCurrent[this.propertyName] : (this.defaultOptIn !== undefined ? this.defaultOptIn : null)
      this.set_optin(this.isoptin)
    }
  },
}
